import axios from 'axios';

import { logout } from '../actions';
import { store } from '../config';
import { apiUrl } from '../environment';
import TokenService from '../services/TokenService';

const instance = axios.create();

const refreshRoute = `${apiUrl}/auth/refresh-token`;

instance.interceptors.request.use(async (config) => {
  try {
    const token = await localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  } catch (err) {
    return Promise.reject(err);
  }
});

instance.interceptors.response.use(
  (successRes) => {
    if (successRes.status === 200) {
      return {
        data:
          successRes.data.status === 1 ? successRes.data.data : successRes.data,
        status: 200,
      };
    }
    return successRes;
  },
  async (error) => {
    const { config: originalConfig, response } = error;

    if (!response) {
      return Promise.reject(error);
    }

    if (response.status === 400 || response.status === 500) {
      return { data: response.data, status: response.status };
    }

    if (response.status === 401) {
      if (response.data.message === 'jwt expired' && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await instance.post(refreshRoute, {
            refreshToken: TokenService.getLocalRefreshToken(),
          });

          const { accessToken, refreshToken } = rs.data;
          TokenService.setLocalAccessToken(accessToken);
          TokenService.setLocalRefreshToken(refreshToken);

          return instance(originalConfig);
        } catch (_error) {
          store.dispatch(logout());
          return Promise.reject(_error);
        }
      }

      store.dispatch(logout());
    }

    return Promise.reject(error);
  }
);

export default instance;
